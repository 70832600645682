import environments from './environments';

const currentEnvironment = environments[window.location.hostname];

export const API_BASE_URL = currentEnvironment.apiBaseUrl;
export const APPLE_ID = currentEnvironment.appleId;
export const APPLE_REDIRECT_URI = currentEnvironment.appleRedirectUri;
export const ENVIRONMENT_NAME = currentEnvironment.name;
export const FACEBOOK_ID = currentEnvironment.facebookId;
export const GOOGLE_ID = currentEnvironment.googleId;
export const LINE_CHANNEL_ID = currentEnvironment.lineChannelId;
export const LINE_REDIRECT_URI = currentEnvironment.lineRedirectUri;
export const ONETRUST_DATA_DOMAIN_SCRIPT = currentEnvironment.oneTrustDataDomainScript;
