const environments = {
  localhost: {
    apiBaseUrl: 'https://oneasics.dev2.asics.com',
    appleId: 'digital.asics.dev.local.id',
    appleRedirectUri: 'https://id.local.dev.asics.digital:8443/api/v2/redirect/apple-sign-in',
    facebookId: '5862568867137054',
    googleId: '1042435755279-lh8cfos5skrlfv43uqjkfip97f9gmoaf.apps.googleusercontent.com',
    lineChannelId: '1653393494',
    lineRedirectUri: 'https://id.local.dev.asics.digital:8443/api/v2/redirect/line-sign-in',
    oneTrustDataDomainScript: 'aa76ace3-3c78-4f9a-8e1a-559c1cc9c158-test',
    name: 'localhost',
  },
  'oneasics.dev1.asics.com': {
    apiBaseUrl: 'https://oneasics.dev1.asics.com',
    appleId: 'digital.asics.dev.oneasics-membership-dev1',
    appleRedirectUri: 'https://oneasics.dev1.asics.com/api/v2/redirect/apple-sign-in',
    facebookId: '5862568867137054',
    googleId: '1042435755279-lh8cfos5skrlfv43uqjkfip97f9gmoaf.apps.googleusercontent.com',
    lineChannelId: '1653576216',
    lineRedirectUri: 'https://oneasics.dev1.asics.com/api/v2/redirect/line-sign-in',
    oneTrustDataDomainScript: 'aa76ace3-3c78-4f9a-8e1a-559c1cc9c158-test',
    name: 'dev1',
  },
  'oneasics.dev2.asics.com': {
    apiBaseUrl: 'https://oneasics.dev2.asics.com',
    appleId: 'com.asics.dev.oneasics',
    appleRedirectUri: 'https://oneasics.dev2.asics.com/api/v2/redirect/apple-sign-in',
    facebookId: '5862568867137054',
    googleId: '1042435755279-lh8cfos5skrlfv43uqjkfip97f9gmoaf.apps.googleusercontent.com',
    lineChannelId: '1653576216',
    // idk why this line redirect doesn't work so we're using id2 for now
    // lineRedirectUri: 'https://oneasics.dev.asics.com/api/v2/redirect/line-sign-in',
    lineRedirectUri: 'https://oneasics.dev2.asics.com/api/v2/redirect/line-sign-in',
    oneTrustDataDomainScript: 'aa76ace3-3c78-4f9a-8e1a-559c1cc9c158-test',
    name: 'temp-dev',
  },
  dev2: {
    name: 'dev2',
  },
  'id-sandbox.asics.com': {
    apiBaseUrl: 'https://id-sandbox.asics.com',
    appleId: 'com.asics.id-sandbox.staging',
    appleRedirectUri: 'https://id-sandbox.asics.com/api/v2/redirect/apple-sign-in',
    facebookId: '2084802561764102',
    googleId: '1042435755279-vhmm3ptpcgnech0lq36nrmjosi1l78uv.apps.googleusercontent.com',
    lineChannelId: '1653576216',
    lineRedirectUri: 'https://id-sandbox.asics.com/api/v2/redirect/line-sign-in',
    oneTrustDataDomainScript: '54ec7074-71d4-4d12-a4e0-e72f26b166fd',
    name: 'staging',
  },
  'id.asics.com': {
    apiBaseUrl: 'https://id.asics.com',
    appleId: 'com.asics.id',
    appleRedirectUri: 'https://id.asics.com/api/v2/redirect/apple-sign-in',
    facebookId: '745812855551846',
    googleId: '1042435755279-lmg3dl0hpggb7m37pvjlkoutqrgvhm8g.apps.googleusercontent.com',
    lineChannelId: '1653576218',
    lineRedirectUri: 'https://id.asics.com/api/v2/redirect/line-sign-in',
    oneTrustDataDomainScript: '94172d86-7c8d-47ba-9e4b-da63d163d789',
    name: 'prod',
  },
};

export default environments;
