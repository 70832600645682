import React, { Suspense } from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from '../../store/store';
import AppBody from '../app-body/AppBody';

const App = () => (
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router basename={process.env.PUBLIC_URL}>
          <Suspense fallback={null}>
            <AppBody id="app-body" />
          </Suspense>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

App.propTypes = {};

App.defaultProps = {};

export default App;
