/* eslint-disable */
import { Button, Link, ObjectHelper, styled } from '@asicsdigital/oneasics-blocks';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { useLogout } from '../../hooks/use-logout';
import { usersApi } from '../../services/api';
import { setPersistableQueryParams } from '../../store/queryParamsSlice';
import { toggleAgeGateVerified } from '../../store/userSlice';

const StyledStringWrapper = styled.div({
  width: '100%',
  wordWrap: 'break-word',
});

const StyledButton = styled(Button) ({
  verticalAlign: 'bottom',
  width: '200px',
});

const StyledLinkWrapper = styled.div({
  marginBottom: '16px',
  textAlign: 'center',

  '& div button' : {
    display: 'inline-block',
  }
});

const DebuggerModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const allStateAreYouInGoodHands = useSelector((state) => state);
  const { i18n } = useTranslation();
  console.log(allStateAreYouInGoodHands);

  const [handleLogout] = useLogout();
  const [deleteUser] = usersApi.useDeleteUserMutation();

  const handleDeleteUser = () => deleteUser().then(handleLogout());
  const handleGetUser = () => dispatch(usersApi.endpoints.getUserInfo.initiate());
  const handleToggleAgeGateVerified = () => dispatch(toggleAgeGateVerified());

  const handleConnectSocial = (provider) => {
    history.push({
      pathname: '/connect-social',
      state: { provider, thirdPartyToken: null },
    });
  };
  const handleConnectApple = () => handleConnectSocial('apple');
  const handleConnectFacebook = () => handleConnectSocial('facebook');
  const handleConnectGoogle = () => handleConnectSocial('google');
  const handleConnectLine = () => handleConnectSocial('line');

  return (
    <>
      <StyledLinkWrapper>
        <h4> Auth Routes </h4>
        <Link path="/change-email">Change Email</Link> &nbsp;
        <Link path="/change-password">Change Password</Link> &nbsp;
        <Link path="/set-password">Set Password</Link> &nbsp;
        <Link path="/continue">Continue</Link> &nbsp;
        <Link path="/delete">Delete Account</Link> &nbsp;
        <Link path="/profile">Profile</Link>&nbsp;
        <Link path="/complete-profile">Complete Profile</Link>&nbsp;
        <Link path="/welcome">Welcome</Link> &nbsp;
      </StyledLinkWrapper>
      <StyledLinkWrapper>
        <h4> No Auth Routes </h4>
        <Link path="/">Lander</Link> &nbsp;
        <Link path="/login">Login</Link> &nbsp;
        <Link path="/register">Register</Link> &nbsp;
        <Link path="/forgot-password">Forgot Password</Link> &nbsp;
        <Link path="/reset-password">Reset Password</Link> &nbsp;
        <Link path="/change-email-confirm">Change Email Confirm</Link> &nbsp;
        <Link path="/logout">Logout</Link> &nbsp;
        <div>
          <Button name="debug-connect-apple" onClick={handleConnectApple}>Connect Apple</Button>
          <Button name="debug-connect-facebook" onClick={handleConnectFacebook}>Connect Facebook</Button>
          <Button name="debug-connect-google" onClick={handleConnectGoogle}>Connect Google</Button>
          <Button name="debug-connect-line" onClick={handleConnectLine}>Connect Line</Button>
        </div>
      </StyledLinkWrapper>
      <StyledStringWrapper>
        <div>Locale: {i18n.language}</div>
        <br />
        <div>URL:</div>
        <div>{window.location.origin + '?' + ObjectHelper.toFormUrlEncoded(allStateAreYouInGoodHands.queryParams)}</div>
        <br />
        <div>Client:</div>
        <div>{JSON.stringify(allStateAreYouInGoodHands.client)}</div>
        <br />
        <div>User:</div>
        <div>{JSON.stringify(allStateAreYouInGoodHands.user)}</div>
        <br />
        <div>Query Params:</div>
        <div>{JSON.stringify(allStateAreYouInGoodHands.queryParams)}</div>
      </StyledStringWrapper>
      <StyledLinkWrapper>
        <div style={{textAlign: 'center'}}>
          <h4>Debugging:</h4>
          <StyledButton name="debug-age-gate" onClick={handleToggleAgeGateVerified}>Toggle Age Gate Verified</StyledButton>
          <StyledButton name="debug-get-user" onClick={handleGetUser}>Debug Get User</StyledButton>
          <StyledButton name="debug-delete-user" onClick={handleDeleteUser}>Delete User</StyledButton>
          <StyledButton name="debug-logout" onClick={handleLogout}>Log out</StyledButton>
        </div>
      </StyledLinkWrapper>
    </>
  );
};

DebuggerModal.propTypes = {};

DebuggerModal.defaultProps = {};

export default DebuggerModal;
