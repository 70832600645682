import { combineReducers, configureStore } from '@reduxjs/toolkit';
import localForage from 'localforage';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import ClientReducer from './clientSlice';
import QueryParamsReducer from './queryParamsSlice';
import UserReducer from './userSlice';
import { clientsApi, oauthApi, usersApi } from '../services/api';

const combinedReducers = combineReducers({
  client: ClientReducer,
  [clientsApi.reducerPath]: clientsApi.reducer,
  [oauthApi.reducerPath]: oauthApi.reducer,
  queryParams: QueryParamsReducer,
  user: UserReducer,
  [usersApi.reducerPath]: usersApi.reducer,
});

const persistConfig = {
  blacklist: [clientsApi.reducerPath, oauthApi.reducerPath, usersApi.reducerPath],
  key: 'root',
  storage: localForage,
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] },
    })
      .concat(clientsApi.middleware)
      .concat(oauthApi.middleware)
      .concat(usersApi.middleware),
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
